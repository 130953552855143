<template>
    <v-container >
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <ASuccessFour :api="this.api"/>
        <div class="d-flex justify-start">
            <v-btn
            @click="redirectBack"
            class="mb-3"
            color="primary">
                <v-icon>
                    mdi-arrow-left-bold
                </v-icon>
            </v-btn>
        </div>
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <div class="d-flex flex-row " v-else>
            <div class="d-flex flex-column flex-grow-1">
                <div>
                  <v-data-table v-if="this.companies!=null"
                  show-select= false
                  :search ="companiesSearch"
                  :items = "companies"
                  :headers = "this.companiesHeader"
                  class="elevation-1"
                  dense
                  item-key="company_id"
                  v-model="selectedCompanies">
                  <template v-slot:top>
                      <v-toolbar flat height="auto">
                          <v-row no-gutters>
                              <v-col>
                                  <v-row class="ma-3">
                                      <v-toolbar-title class="font-weight-bold text-h4">
                                          Companies
                                      </v-toolbar-title>
                                  </v-row>
                                  <v-row class="mt-5">
                                      <v-text-field class="mx-4" dense outlined label="Search" v-model="companiesSearch">
                                      </v-text-field>
                                  </v-row>
                              </v-col>
                          </v-row>
                      </v-toolbar>
                  </template>
                  </v-data-table>
              </div>
            </div>
            <div class="d-flex flex-column flex-grow-1">
                <div class="ml-3">
                    <div v-if="this.selectedCompanies!=null && Object.keys(this.selectedCompanies).length!=0">

                        <div class="mb-3">
                            <v-card>
                                <v-toolbar color="secondary white--text text-h7">
                                    <v-row no-gutters class="mx-3">
                                        <v-col>
                                            <v-row>
                                                <v-toolbar-title>
                                                    Selected companies
                                                </v-toolbar-title>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-toolbar>
                                <div class="pt-3" style="max-height: 300px;overflow-y: scroll;">
                                    <div v-for="(company,index) in this.selectedCompanies"
                                    :key="index"
                                    >
                                        <v-list-item dense outlined elevation-1>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ (index+1)+" . " }} {{ company.company_name }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </div>
                                </div>
                            </v-card>
                        </div>

                        <div >
                            <v-card class="">
                                <v-toolbar color="secondary white--text text-h6">
                                    <v-row no-gutters class="ma-3">
                                        <v-col>
                                            <v-row>
                                                <v-toolbar-title>
                                                    Task
                                                </v-toolbar-title>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-toolbar>
                                <div class="pa-5">
                                    <v-autocomplete dense outlined v-model="form.priority" 
                                    label="Priority"
                                    :items="priorityOption">
                                        
                                    </v-autocomplete>
                                    <v-autocomplete dense outlined v-model="form.category" label = "Category" :items="categoryOption">
                                    </v-autocomplete>
                                    <v-textarea filled dense v-model="form.action" placeholder="Description..." solo :counter="500" :rules="rulesMaxLength">

                                    </v-textarea>
                                    <!-- <mavon-editor
                                    style="z-index:0;height:auto"
                                    defaultOpen = "edit"
                                    :toolbarsFlag = "false"
                                    language="en"
                                    v-model="form.action"
                                    placeholder="Description...">
                                    </mavon-editor> -->
                            
                                </div>
                                <div class="d-flex justify-end pb-3 px-3">
                                    <v-btn color="primary" @click="redirectBack" plain class="mx-2">
                                        Cancel
                                    </v-btn>
                                    <v-btn color="primary" @click="validateInput">
                                        Confirm
                                    </v-btn>
                                </div>
                            </v-card>
                        </div>
                           
                       
                    </div>
                    <div v-else>
                        <v-card>
                            <v-toolbar color="secondary white--text text-h7">
                                <v-row no-gutters class="mx-3">
                                    <v-col>
                                        <v-row>
                                            <v-toolbar-title>
                                                Selected companies
                                            </v-toolbar-title>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-toolbar>
                            <div class="pt-3">
                                <span class="text-h7 ma-3 grey--text">
                                    No Company Selected
                                </span>
                            </div>
                        </v-card>
                    </div>
                </div>
            </div>
        </div>


        <!-- <v-row  v-else>
            <v-col>
              

            </v-col>
            <v-col class="">
            
            </v-col>
        </v-row> -->
    </v-container>
</template>

<script>
// import mavonEditor from 'mavon-editor';
import AConfirmation from '../../components/common/AConfirmation.vue';
import ASuccessFour from '../../components/common/ASuccessFour.vue';
export default{
    components:{
        // "mavon-editor":mavonEditor.mavonEditor,
        AConfirmation,
        ASuccessFour,
    },
    data: () => ({
        rulesMaxLength:[v=>v.length<=500||'Max 500 Characters'],
        isPending:false,
        companies:null,
        companiesSearch:null,
        categoryOption:[
            'Renewal','Check Payment Status With Financial Department','Payment Failed','Check Credit Card Auto Deduct','Others'
        ],
        priorityOption:[
            'Low','Medium','Urgent'
        ],
        companiesHeader:[
        {
                text:'#',
                value:'index',
                align:'start',
            },
            {
                text:'Company Name',
                value:'company_name',
            },
            {
                text:'Plan',
                value:'plan_name',
            },
            {
                text:'Subsription Status',
                value:'verification',
            },
            {
                text:'Subscribed Date',
                value:'created_at',
            },
        ],
        selectedCompanies:null,
        form:{
            priority:null,
            category:null,
            pic:null,
            action:null,
        },
        api:{
            isSuccesful:false,
            isLoading:false,
            isError:false,
            error:null,
            success:null,
            url:null,
        },
    }),

    created() {
        this.api.callbackReset = () => 
        {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) =>
        {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) =>
        {

            if(resp.class=="getCompaniesClientActiveAssigned"){
                this.companies = resp.data.companies;
                this.totalCompanies = resp.data.total;
            }
            if(resp.class=="multipleManualSaveTask"){
                this.api.isSuccesful = true;
                this.api.success="Succesfully Assigned Task";
            }
            this.api.isError = false;
            this.api.isLoading = false;

        }
    },
    mounted() {
        this.fetch();
        
    },
    methods:{
        fetch(){
            let fetchCompaniesApi = this.fetchCompanies();
            this.$api.fetch(fetchCompaniesApi);
        },
        fetchCompanies(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/pic/company?userId="+this.$router.history.current.params.id;
            return tempApi;
        },
        redirectBack(){
            this.$router.go(-1);
        },
        validateInput(){
            if(this.form.action.length>500){
                return 0;
            }
            this.isPending = true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = false;
            let createMassAssignationApi = this.createMassAssignation();

            this.$api.fetch(createMassAssignationApi);
        },
        createMassAssignation(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/task/multiple/manual/id/"+this.$router.history.current.params.id;
            
            let formData = new FormData;
            this.selectedCompanies.forEach(company=>{
                formData.append('companyId[]',company.company_id);
            });
            formData.append('category',this.form.category),
            formData.append('status','To do'),
            formData.append('priority',this.form.priority),
            formData.append('action',this.form.action),
            formData.append('assignedId',this.$store.getters.getUserId);

            tempApi.params = formData;
            return tempApi;
        }

    }
}
</script>